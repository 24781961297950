import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"

import { KettleVolume } from "./components/KettleVolume"
import { PrimingSugar } from "./components/PrimingSugar"
import { RefractometerConverter } from "./components/RefractometerConverter"
import { DilutionAndBoilOff } from "./components/DilutionAndBoilOff"

const components = {
  KettleVolume,
  PrimingSugar,
  RefractometerConverter,
  DilutionAndBoilOff,
}

export function renderAll() {
  const containers = document.querySelectorAll("[data-react]")

  containers.forEach((container) => {
    const componentName = container.dataset.react
    const Component = components[componentName]

    if (Component === undefined || Component === null) {
      console.error(`Component not found: ${container.dataset.react}`, container)
      return
    }

    const root = createRoot(container, {
      identifierPrefix: `${componentName}-`,
    })

    root.render(
      <StrictMode>
        <Component />
      </StrictMode>,
    )
  })
}
