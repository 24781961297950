import React, { useId } from "react"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"

import { isNotNil } from "es-toolkit"
import useLocalStorageState from "use-local-storage-state"

export const volumeToDepth = (radius, volume) => {
  // V = π × r² × h
  return (volume * 1000) / (radius ** 2 * Math.PI)
}

export const depthToVolume = (radius, depth) => {
  // V = π × r² × h
  return (radius ** 2 * depth * Math.PI) / 1000
}

export const KettleVolume = () => {
  const keyPrefix = "kettleVolume"

  const [kettleDiameter, setKettleDiameter] = useLocalStorageState(`${keyPrefix}→kettleDiameter`, {
    defaultValue: "",
  })
  const [kettleVolume, setKettleVolume] = useLocalStorageState(`${keyPrefix}→kettleVolume`, {
    defaultValue: "",
  })
  const [kettleDepth, setKettleDepth] = useLocalStorageState(`${keyPrefix}→kettleDepth`, {
    defaultValue: "",
  })

  const kettleDiameterId = useId()
  const kettleVolumeId = useId()
  const kettleDepthId = useId()

  return (
    <Form onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor={kettleDiameterId}>Kettle diameter</Form.Label>
        <InputGroup>
          <Form.Control
            id={kettleDiameterId}
            type="number"
            placeholder="Kettle diameter in centimetres"
            aria-label="Kettle diameter"
            value={kettleDiameter}
            onChange={(event) => {
              setKettleDiameter(event.target.value)

              if (isNotNil(kettleVolume)) {
                setKettleDepth("")
              }

              if (isNotNil(kettleDepth)) {
                setKettleVolume("")
              }
            }}
            min={1}
            step={0.1}
          />
          <InputGroup.Text>cm</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <hr />

      <Form.Group className="mb-3">
        <Form.Label htmlFor={kettleVolumeId}>Volume</Form.Label>
        <InputGroup>
          <Form.Control
            id={kettleVolumeId}
            type="number"
            placeholder="Kettle volume in litres"
            aria-label="Kettle volume"
            aria-describedby="kettleVolumeHelpBlock"
            value={kettleVolume}
            onChange={(event) => {
              setKettleVolume(event.target.value)
              if (isNotNil(kettleDiameter)) {
                const depth = volumeToDepth(kettleDiameter / 2, event.target.value)
                setKettleDepth(parseFloat(depth.toFixed(1)))
              }
            }}
            min={1}
            step={0.1}
          />
          <InputGroup.Text>ℓ</InputGroup.Text>
        </InputGroup>
        <Form.Text id="kettleVolumeHelpBlock" muted>
          The volume of liquid in the kettle
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={kettleDepthId}>Depth</Form.Label>
        <InputGroup>
          <Form.Control
            id={kettleDepthId}
            type="number"
            placeholder="Kettle depth in centimetres"
            aria-label="Kettle depth"
            aria-describedby="kettleDepthHelpBlock"
            value={kettleDepth}
            onChange={(event) => {
              setKettleDepth(event.target.value)
              if (isNotNil(kettleDiameter)) {
                const volume = depthToVolume(kettleDiameter / 2, event.target.value)
                setKettleVolume(parseFloat(volume.toFixed(1)))
              }
            }}
            min={0}
            step={0.1}
          />
          <InputGroup.Text>cm</InputGroup.Text>
        </InputGroup>
        <Form.Text id="kettleDepthHelpBlock" muted>
          The depth of liquid in the kettle
        </Form.Text>
      </Form.Group>
    </Form>
  )
}
