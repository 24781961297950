import React, { useId } from "react"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"

import useLocalStorageState from "use-local-storage-state"

import { format, hasValue, defaultValue } from "../utilities"

const calculateNewVolume = (currentVolume, currentGravity, desiredGravity) => {
  // End Volume=Beg Volume * Beg Gravity / End Gravity
  return (currentVolume * (currentGravity * 1000 - 1000)) / (desiredGravity * 1000 - 1000)
}

const calculateNewGravity = (currentVolume, currentGravity, desiredVolume) => {
  // End Gravity=Beg Volume * Beg Gravity / End Volume
  const points = (currentVolume * (currentGravity * 1000 - 1000)) / desiredVolume
  return (points + 1000) / 1000
}

const conditionalClass = (diff) => {
  if (!hasValue(diff) || diff === 0) {
    return ""
  } else if (diff < 0) {
    return "text-negative"
  } else {
    return "text-positive"
  }
}

const formatDifference = (diff, decimals) => {
  if (!hasValue(diff)) {
    return ""
  }

  return diff > 0 ? `+${format(diff, decimals)}` : format(diff, decimals)
}

export const CalculateNewVolume = () => {
  const keyPrefix = "CalculateNewVolume"
  const [currentVolume, setCurrentVolume] = useLocalStorageState(`${keyPrefix}→currentVolume`, {
    defaultValue: "",
  })
  const [currentGravity, setCurrentGravity] = useLocalStorageState(`${keyPrefix}→currentGravity`, {
    defaultValue: "",
  })
  const [desiredGravity, setDesiredGravity] = useLocalStorageState(`${keyPrefix}→desiredGravity`, {
    defaultValue: "",
  })

  const currentVolumeId = useId()
  const currentGravityId = useId()
  const desiredGravityId = useId()
  const newVolumeId = useId()
  const differenceId = useId()

  const newVolume =
    hasValue(currentVolume) && hasValue(currentGravity) && hasValue(desiredGravity)
      ? calculateNewVolume(currentVolume, currentGravity, desiredGravity)
      : ""

  const difference = hasValue(newVolume) ? newVolume - currentVolume : ""

  return (
    <Form onSubmit={(event) => event.preventDefault()}>
      <h2>The target gravity is known, the new volume is unknown</h2>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={currentVolumeId}>Current volume</Form.Label>
        <InputGroup>
          <Form.Control
            id={currentVolumeId}
            type="number"
            placeholder="Volume of liquid in litres"
            aria-label="Volume of liquid in the kettle"
            value={currentVolume}
            onChange={(event) => setCurrentVolume(event.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={currentGravityId}>Current gravity</Form.Label>
        <InputGroup>
          <Form.Control
            id={currentGravityId}
            type="number"
            placeholder="Current gravity of the liquid, e.g. 1.040"
            aria-label="Current gravity of the liquid"
            value={currentGravity}
            onChange={(event) => setCurrentGravity(event.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={desiredGravityId}>Desired gravity</Form.Label>
        <InputGroup>
          <Form.Control
            id={desiredGravityId}
            type="number"
            placeholder="Desired gravity of the liquid, e.g. 1.050"
            aria-label="Desired gravity of the liquid"
            value={desiredGravity}
            onChange={(event) => setDesiredGravity(event.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={newVolumeId}>New volume</Form.Label>
        <InputGroup>
          <Form.Control
            id={newVolumeId}
            type="text"
            aria-label="New volume of liquid in litres"
            disabled
            value={format(newVolume, 2)}
          />
          <InputGroup.Text>ℓ</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={differenceId}>Difference</Form.Label>
        <InputGroup>
          <Form.Control
            id={differenceId}
            type="text"
            aria-label="The difference between the original and new volume in litres"
            disabled
            value={formatDifference(difference, 2)}
            className={conditionalClass(difference)}
          />
          <InputGroup.Text>ℓ</InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}

export const CalculateNewGravity = () => {
  const keyPrefix = "CalculateNewGravity"
  const [currentVolume, setCurrentVolume] = useLocalStorageState(`${keyPrefix}→currentVolume`, {
    defaultValue: "",
  })
  const [currentGravity, setCurrentGravity] = useLocalStorageState(`${keyPrefix}→currentGravity`, {
    defaultValue: "",
  })
  const [desiredVolume, setDesiredVolume] = useLocalStorageState(`${keyPrefix}→desiredVolume`, {
    defaultValue: "",
  })

  const newGravity =
    hasValue(currentVolume) && hasValue(currentGravity) && hasValue(desiredVolume)
      ? calculateNewGravity(currentVolume, currentGravity, desiredVolume)
      : null
  const difference = hasValue(newGravity) ? newGravity - currentGravity : null

  const currentVolumeId = useId()
  const currentGravityId = useId()
  const desiredVolumeId = useId()
  const newGravityId = useId()
  const differenceId = useId()

  return (
    <Form onSubmit={(event) => event.preventDefault()}>
      <h2>The target volume is known, the new gravity is unknown</h2>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={currentVolumeId}>Current volume</Form.Label>
        <InputGroup>
          <Form.Control
            id={currentVolumeId}
            type="number"
            placeholder="Volume of liquid in litres"
            aria-label="Volume of liquid in the kettle"
            value={currentVolume}
            onChange={(event) => setCurrentVolume(event.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={currentGravityId}>Current gravity</Form.Label>
        <InputGroup>
          <Form.Control
            id={currentGravityId}
            type="number"
            placeholder="Current gravity of the liquid, e.g. 1.040"
            aria-label="Current gravity of the liquid"
            value={currentGravity}
            onChange={(event) => setCurrentGravity(event.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={desiredVolumeId}>Desired volume</Form.Label>
        <InputGroup>
          <Form.Control
            id={desiredVolumeId}
            type="number"
            placeholder="Desired volume in litres"
            aria-label="Desired volume in litres"
            value={desiredVolume}
            onChange={(event) => setDesiredVolume(event.target.value)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={newGravityId}>New gravity</Form.Label>
        <InputGroup>
          <Form.Control
            id={newGravityId}
            type="text"
            aria-label="New gravity of the liquid"
            disabled
            value={format(newGravity, 3)}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={differenceId}>Difference</Form.Label>
        <InputGroup>
          <Form.Control
            id={differenceId}
            type="text"
            aria-label="The difference between the original and new gravity"
            disabled
            className={conditionalClass(difference)}
            value={formatDifference(difference, 3)}
          />
        </InputGroup>
      </Form.Group>
    </Form>
  )
}

export const DilutionAndBoilOff = () => {
  return (
    <>
      <div className="mb-5">
        <CalculateNewVolume />
      </div>
      <div className="mb-5">
        <CalculateNewGravity />
      </div>
    </>
  )
}
