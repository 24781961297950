[
  {
    "name": "Sucrose",
    "factor": 4,
    "weightFactor": 0.91
  },
  {
    "name": "Corn Sugar",
    "factor": 4,
    "weightFactor": 1
  },
  {
    "name": "Turbinado",
    "factor": 4,
    "weightFactor": 0.91
  },
  {
    "name": "Demarara",
    "factor": 4,
    "weightFactor": 0.91
  },
  {
    "name": "Dextrose",
    "factor": 4,
    "weightFactor": 1
  },
  {
    "name": "Corn Syrup",
    "factor": 5.3,
    "weightFactor": 1
  },
  {
    "name": "Brown Sugar",
    "factor": 4.1,
    "weightFactor": 1
  },
  {
    "name": "Molasses",
    "factor": 5.1,
    "weightFactor": 1
  },
  {
    "name": "Maple Syrup",
    "factor": 4.7,
    "weightFactor": 1
  },
  {
    "name": "Sorghum Syrup",
    "factor": 5.3,
    "weightFactor": 1
  },
  {
    "name": "Honey",
    "factor": 4.9,
    "weightFactor": 1
  },
  {
    "name": "DME",
    "factor": 5.33,
    "weightFactor": 1
  },
  {
    "name": "DME (Laaglander)",
    "factor": 7.3,
    "weightFactor": 1
  },
  {
    "name": "Belgian Candy Syrup",
    "factor": 5.75,
    "weightFactor": 1
  },
  {
    "name": "Belgian Candy Sugar",
    "factor": 4.85,
    "weightFactor": 1
  },
  {
    "name": "Invert Sugar Syrup",
    "factor": 4,
    "weightFactor": 1
  },
  {
    "name": "Black Treacle",
    "factor": 4.2,
    "weightFactor": 1
  },
  {
    "name": "Rice Solids",
    "factor": 4.6,
    "weightFactor": 1
  }
]
