import { SRM2RGB } from "./utilities"

window.addEventListener("DOMContentLoaded", () => {
  const bars = document.querySelectorAll(".bar[data-low][data-high]")
  bars.forEach((bar) => {
    const inner = bar.querySelector(".inner_bar")
    const high = parseFloat(bar.dataset.high)
    const low = parseFloat(bar.dataset.low)
    const max = parseFloat(bar.dataset.max)
    const min = parseFloat(bar.dataset.min)
    const range = high - low
    const totalRange = max - min
    const width = (range / totalRange) * 100
    const left = ((low - min) / totalRange) * 100
    inner.style.left = `${left}%`
    inner.style.width = `${width}%`
  })

  const srm = document.querySelector(".one_stat.srm")
  if (srm) {
    const srmBar = srm.querySelector(".bar[data-low][data-high]")
    const gradient = srm.querySelector(".gradient")

    if (srmBar) {
      const high = parseInt(srmBar.dataset.high, 10)
      const low = parseInt(srmBar.dataset.low, 10)
      const stops = []

      for (let i = low; i <= high; i++) {
        stops.push(SRM2RGB[i])
      }

      if (stops.length) {
        // Ensure that there are at least two stops
        if (stops.length === 1) {
          stops.push(stops[0])
        }

        const innerBarColor = stops[Math.floor(stops.length / 2)]
        for (const innerBar of document.querySelectorAll(".inner_bar")) {
          innerBar.style.background = innerBarColor
        }
      }

      gradient.style.background = `linear-gradient(to right, ${stops.join(", ")})`
    }
  }
})
