import React, { useId } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Table from "react-bootstrap/Table"

import { isNotNil } from "es-toolkit"
import useLocalStorageState from "use-local-storage-state"

import styles from "../data/co2volumes.json"
import sugars from "../data/sugars.json"

export const calculateDissolvedCO2 = (temperature) => {
  const log = Math.log(temperature * 1.8 + 32).toFixed(15)
  return -0.9753 * log + 4.9648
}

export const calculateSugarGrams = (sugar, desiredCO2, beerVolume, temperature) => {
  const co2Factor = desiredCO2 - calculateDissolvedCO2(temperature)
  return co2Factor * sugar.factor * beerVolume * sugar.weightFactor
}

export const PrimingSugar = () => {
  const keyPrefix = "primingSugar"
  const [desiredCO2, setDesiredCO2] = useLocalStorageState(`${keyPrefix}→desiredCO2`, {
    defaultValue: "",
  })
  const [temperature, setTemperature] = useLocalStorageState(`${keyPrefix}→temperature`, {
    defaultValue: "",
  })
  const [beerVolume, setBeerVolume] = useLocalStorageState(`${keyPrefix}→beerVolume`, {
    defaultValue: "",
  })

  const desiredCO2Id = useId()
  const temperatureId = useId()
  const beerVolumeId = useId()

  const canCalculate = isNotNil(temperature) && isNotNil(desiredCO2) && isNotNil(beerVolume)

  return (
    <Form onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor={desiredCO2Id}>Desired CO₂</Form.Label>
        <InputGroup>
          <Form.Control
            id={desiredCO2Id}
            type="number"
            placeholder="Desired CO₂ in volumes"
            aria-label="Desired CO₂ in volumes"
            value={desiredCO2}
            onChange={(event) => setDesiredCO2(event.target.value)}
            min={0}
            max={8}
            step={0.1}
          />
          <InputGroup.Text>volumes</InputGroup.Text>
          <DropdownButton variant="outline-secondary" title="Select style" id="input-group-dropdown-1">
            {styles.map((style, index) => (
              <Dropdown.Item
                as="button"
                key={index}
                onClick={(event) => {
                  event.preventDefault()
                  setDesiredCO2(style.value)
                }}
              >
                {style.name} ({style.value} volumes)
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={temperatureId}>Temperature</Form.Label>
        <InputGroup>
          <Form.Control
            id={temperatureId}
            type="number"
            placeholder="The current temperature of the beer"
            aria-label="The current temperature of the beer"
            value={temperature}
            onChange={(event) => setTemperature(event.target.value)}
            min={0}
            max={99}
            step={0.1}
          />
          <InputGroup.Text>°C</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={beerVolumeId}>Beer volume</Form.Label>
        <InputGroup>
          <Form.Control
            id={beerVolumeId}
            type="number"
            placeholder="The volume of beer in litres"
            aria-label="The volume of beer in litres"
            value={beerVolume}
            onChange={(event) => setBeerVolume(event.target.value)}
            min={0}
            step={0.1}
          />
          <InputGroup.Text>ℓ</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Table striped bordered>
        <thead>
          <tr>
            <th>Sugar</th>
            <th>Grams</th>
          </tr>
        </thead>
        <tbody>
          {sugars.map((sugar, index) => (
            <tr key={index}>
              <td>{sugar.name}</td>
              <td>{canCalculate ? calculateSugarGrams(sugar, desiredCO2, beerVolume, temperature).toFixed(1) : "—"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Form>
  )
}
