[
  { "value": 2.3, "name": "American Amber Ale" },
  { "value": 2.1, "name": "American Barley Wine" },
  { "value": 2.4, "name": "American Brown Ale" },
  { "value": 2.4, "name": "American Pale Ale" },
  { "value": 2.6, "name": "American Wheat Beer" },
  { "value": 2.2, "name": "Baltic Porter" },
  { "value": 2.2, "name": "Barley Wine" },
  { "value": 2.4, "name": "Bavarian Dunkel" },
  { "value": 3.6, "name": "Bavarian Hefe Weizen" },
  { "value": 2.4, "name": "Bavarian Helles" },
  { "value": 2.9, "name": "Belgian Blond" },
  { "value": 3.0, "name": "Belgian Dubbel" },
  { "value": 3.3, "name": "Belgian Strong Golden Ale" },
  { "value": 3.3, "name": "Belgian Tripel" },
  { "value": 3.3, "name": "Bière de Garde" },
  { "value": 2.5, "name": "Bock" },
  { "value": 2.0, "name": "Breakfast Stout" },
  { "value": 1.5, "name": "British Bitter" },
  { "value": 2.2, "name": "British Old Ale" },
  { "value": 2.6, "name": "California Common" },
  { "value": 2.6, "name": "Cream Ale" },
  { "value": 2.3, "name": "Czech Pilsner" },
  { "value": 2.4, "name": "Dopplebock" },
  { "value": 2.2, "name": "Double IPA" },
  { "value": 1.9, "name": "Dry Irish Stout" },
  { "value": 3.2, "name": "Dunkelweizen" },
  { "value": 2.1, "name": "English Brown Ale" },
  { "value": 1.9, "name": "English Pale Ale" },
  { "value": 1.7, "name": "Extra Special Bitter" },
  { "value": 2.3, "name": "German Alt" },
  { "value": 2.2, "name": "Imperial Stout" },
  { "value": 2.4, "name": "India pale ale" },
  { "value": 2.2, "name": "Irish Red Ale" },
  { "value": 2.7, "name": "Kölsch" },
  { "value": 2.3, "name": "Maibock" },
  { "value": 1.8, "name": "Mild Ale" },
  { "value": 2.5, "name": "Oktoberfest" },
  { "value": 2.5, "name": "Phat Tyre Amber Ale" },
  { "value": 2.1, "name": "Porter" },
  { "value": 2.3, "name": "Rauchbier" },
  { "value": 3.1, "name": "Roggenbier" },
  { "value": 3.2, "name": "Saison" },
  { "value": 2.3, "name": "Schwarzbier" },
  { "value": 1.9, "name": "Scottish 60/-" },
  { "value": 2.0, "name": "Scottish 80/-" },
  { "value": 2.1, "name": "Scottish Wee Heavy" },
  { "value": 2.1, "name": "Stout" },
  { "value": 2.2, "name": "Winter Ale" },
  { "value": 3.0, "name": "Witbier" }
]
