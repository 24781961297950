import React, { useId } from "react"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"

import useLocalStorageState from "use-local-storage-state"

import { format, hasValue, defaultValue } from "../utilities"

const DEFAULT_CORRECTION_FACTOR = 1.04

const calculateOriginalGravity = (ri, correctionFactor) => {
  // ((stats.originalRI÷wortCorrectionFactor)÷(258.6−(((C7÷wortCorrectionFactor)÷258.2)×227.1))+1)
  correctionFactor = defaultValue(correctionFactor, DEFAULT_CORRECTION_FACTOR)

  const x = 258.6
  const y = 258.2
  const z = 227.1

  return ri / correctionFactor / (x - (ri / correctionFactor / y) * z) + 1
}

const calculateFinalGravity = (oRI, fRI, cF) => {
  cF = defaultValue(cF, DEFAULT_CORRECTION_FACTOR)

  const o = 1
  const b = 0.0044993
  const c = 0.0117741
  const d = 0.000275806
  const e = 0.00127169
  const f = 0.00000727999
  const g = 0.0000632929

  const coRI = oRI / cF
  const cfRI = fRI / cF

  return o - b * coRI + c * cfRI + d * coRI ** 2 - e * cfRI ** 2 - f * coRI ** 3 + g * cfRI ** 3
}

const calculateABV = (oRI, fRI, cF) => {
  // (0.01÷0.8192)×((coRI)−(0.1808×(coRI)+0.8192×(668.72×fg−463.37−205.347×fg^2)))÷(2.0665−0.010665×(coRI))
  cF = defaultValue(cF, DEFAULT_CORRECTION_FACTOR)

  const fg = calculateFinalGravity(oRI, fRI, cF)
  const coRI = oRI / cF

  const a = 0.01
  const b = 0.8192
  const c = 0.1808
  const d = 668.72
  const e = 463.37
  const f = 205.347
  const g = 2.0665
  const h = 0.010665

  return (((a / b) * (coRI - (c * coRI + b * (d * fg - e - f * fg ** 2)))) / (g - h * coRI)) * 100
}

export const RefractometerConverter = () => {
  const keyPrefix = "refractometer"

  const [originalRI, setOriginalRI] = useLocalStorageState(`${keyPrefix}→originalRI`, {
    defaultValue: "",
  })
  const [finalRI, setFinalRI] = useLocalStorageState(`${keyPrefix}→finalRI`, {
    defaultValue: "",
  })
  const [wortCorrectionFactor, setWortCorrectionFactor] = useLocalStorageState(`${keyPrefix}→wortCorrectionFactor`, {
    defaultValue: DEFAULT_CORRECTION_FACTOR.toFixed(3),
  })

  const og = hasValue(originalRI) ? calculateOriginalGravity(originalRI, wortCorrectionFactor) : ""
  const fg =
    hasValue(originalRI) && hasValue(finalRI) ? calculateFinalGravity(originalRI, finalRI, wortCorrectionFactor) : ""
  const abv = hasValue(originalRI) && hasValue(finalRI) ? calculateABV(originalRI, finalRI, wortCorrectionFactor) : ""

  const originalRIId = useId()
  const finalRIId = useId()
  const wortCorrectionFactorId = useId()
  const ogId = useId()
  const fgId = useId()
  const abvId = useId()

  return (
    <Form onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor={originalRIId}>Original RI</Form.Label>
        <InputGroup>
          <Form.Control
            id={originalRIId}
            type="number"
            placeholder="e.g. 9.0"
            aria-label="Original refractive index in degrees Brix"
            value={originalRI}
            onChange={(event) => {
              setOriginalRI(event.target.value)
            }}
          />
          <InputGroup.Text>°Bx</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={finalRIId}>Final RI</Form.Label>
        <InputGroup>
          <Form.Control
            id={finalRIId}
            type="number"
            placeholder="e.g. 8.0"
            aria-label="Final refractive index in degrees Brix"
            value={finalRI}
            onChange={(event) => {
              setFinalRI(event.target.value)
            }}
          />
          <InputGroup.Text>°Bx</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={wortCorrectionFactorId}>Wort correction factor</Form.Label>
        <InputGroup>
          <Form.Control
            id={wortCorrectionFactorId}
            type="number"
            placeholder="Default: 1.040"
            aria-label="Optional wort correction factor. Defaults to 1.040."
            value={wortCorrectionFactor}
            onChange={(event) => {
              setWortCorrectionFactor(event.target.value)
            }}
          />
          <InputGroup.Text>°Bx</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={ogId}>OG</Form.Label>
        <InputGroup>
          <Form.Control
            id={ogId}
            type="number"
            placeholder="Original gravity"
            aria-label="Original gravity"
            disabled
            value={format(og, 3) || ""}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={fgId}>Estimated FG</Form.Label>
        <InputGroup>
          <Form.Control
            id={fgId}
            type="number"
            placeholder="Final gravity"
            aria-label="Final gravity"
            disabled
            value={format(fg, 3) || ""}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor={abvId}>Estimated ABV</Form.Label>
        <InputGroup>
          <Form.Control
            id={abvId}
            type="number"
            placeholder="Estimated alcohol by volume"
            aria-label="Estimated alcohol by volume"
            disabled
            value={format(abv, 1) || ""}
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <p>
        Based on Sean Terrill's{" "}
        <a href="http://seanterrill.com/2011/04/07/refractometer-fg-results/">FG Calculator spreadsheet</a>
      </p>
    </Form>
  )
}
