import "$styles/index.scss"
import "$styles/syntax-highlighting.css"

import { Tab } from "bootstrap"

import { renderAll } from "./react-components"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import "./style"

// Import all images from frontend/images
import images from "../images/**/*.{jpg,jpeg,png,svg,gif,ico}"
Object.entries(images).forEach((image) => image)

const triggerTabList = document.querySelectorAll("#editionTabs button")
triggerTabList.forEach((triggerEl) => {
  const tabTrigger = new Tab(triggerEl)

  triggerEl.addEventListener("click", (event) => {
    console.log("clicked")
    event.preventDefault()
    tabTrigger.show()
  })
})

window.addEventListener("DOMContentLoaded", () => {
  renderAll()
})

console.info("Bridgetown is loaded!")
